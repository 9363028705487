import { FetchAction } from '@happenings/components/common/types';
import { AnyAction } from 'redux';

import * as types from '@happenings/components/constants/actionTypes';
import Store from '../store';

export const actionTypes = {
  INIT_SESSION: 'INIT_SESSION',
  LOGIN_USER: 'LOGIN_USER',
  LOGOUT_USER: 'LOGOUT_USER',
};

export type CurrentUser = {
  username: string;
  id: number;
  token?: string;
};

// interface for slice of state
export interface Session {
  currentUser?: CurrentUser;
  proceedWithoutLogin: boolean;
}

interface Action {
  type: string;
  currentUser: CurrentUser;
}

// Constants
export const API_LOGIN_ERR_MSG = 'username and/or password incorrect';

/**
 * Selectors
 */
export const getToken = (state: Store): string | undefined =>
  state.session?.currentUser?.token;

export const getCurrUser = (state: Store): CurrentUser | undefined =>
  state.session?.currentUser;

/**
 * ACTION CREATORS
 */

/**
 *
 * @param {bool} authRequired indicates if session should be authenticated
 */

export const initSession = (
  authRequired = true,
  token = undefined
): FetchAction => {
  return {
    type: actionTypes.INIT_SESSION,
    payload: token ? { authRequired, token } : { authRequired },
  };
};

export const loginUser = (username: string, password: string): FetchAction => ({
  type: actionTypes.LOGIN_USER,
  payload: { username, password },
});

export const logoutUser = (): FetchAction => ({
  type: actionTypes.LOGOUT_USER,
  payload: {},
});

export const DEFAULT_SESSION: Session = {
  currentUser: undefined,
  proceedWithoutLogin: false,
};

const sessionReducer = (
  state = DEFAULT_SESSION,
  action: AnyAction
): Session => {
  switch (action.type) {
    case types.RECEIVE_CURRENT_USER:
      return {
        ...state,
        currentUser: action.currentUser,
        proceedWithoutLogin: false,
      };
    case actionTypes.LOGOUT_USER:
      return DEFAULT_SESSION;
    case types.CLEAR_CURRENT_USER:
      return { ...DEFAULT_SESSION, proceedWithoutLogin: true };
    case types.PROCEED_WITHOUT_USER_LOGIN:
      return { ...DEFAULT_SESSION, proceedWithoutLogin: true };
    default:
      return state;
  }
};

export default sessionReducer;
