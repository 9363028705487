import * as React from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { getFetchParams } from '@happenings/components/util';
import { Link, withRouter } from 'react-router-dom';

import UpdateButton from '../util/UpdateButton';
import { EMAIL_FMT_PATTERN } from '../../constants';

import LogoSvg from '../../assets/newlogo.svg';

const WaitlistSignup: React.FC<{}> = () => {
  type formFields = { email: string; };
  const { register, handleSubmit, errors, formState } = useForm<formFields>({
    mode: 'onSubmit',
  });
  const { isValid } = formState;

  const [ submitted, setSubmitted ] = React.useState(false);


  const onSubmit = (data: formFields) => {
    axios.post(
      `/api/public/waitlist`,
      { email: data.email },
      getFetchParams(),
    );
    // optimistic UI update
    setSubmitted(true);
  };

  if (submitted) {
    return (
      <div className="form center-text text-body">
        Thanks! We'll be in touch.
      </div>
    );
  }

  return (
    <div className="form">
      <div className="container">
          <h1 className="text-body">Happenings</h1>
          <img loading="eager" className="logo" src={LogoSvg} alt="Happenings Logo" />
          <h2 className="text-body center-text">For social and community organizing</h2>
      </div>
      <div>
      </div>
      <Link className="enter-btn" to="/start">
        <h3 className="text-body">Sign Up</h3>
      </Link>

      <div className="footer-container">
        <div className="legal-page-links">
          <a href="mailto:hello@whatshappenings.com" target="_blank" rel="noreferrer">Contact</a>
          <a href="/about">About</a>
          <a href="/eula">EULA</a>
          <span><a href="/terms">Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a></span>
          {/* <a href="">App Store</a> */}
        </div>
        <h4 className="text-small center uppercase">Spring 2024</h4>
        <h4 className="text-small text">Impossible Effort, Inc { new Date ().getFullYear () }</h4>
      </div>
    </div>
  );
};

export default withRouter(WaitlistSignup);
